<template>
    <div class="comments">
        <comment-form
            :account-id="accountId"
            :comment="comment"
            :is-lead="isLead"
            @commentCreated="commentCreated"
            @commentUpdated="commentUpdated"
            @clearForm="comment = null"
            v-if="CAN([scope])"
        />
        <ui-list-group-skeleton v-if="loading" :rows="2" />
        <comment-list v-else class="mt-3" :comments="notes" @editComment="editComment" :scope="scope" />
    </div>
</template>

<script>
import CommentForm from '@/components/accounts/CommentForm';
import CommentList from '@/components/accounts/CommentList';
import UiListGroupSkeleton from '@/components/ui/skeleton/ListGroupSkeleton.vue';

export default {
    name: 'Comments',
    components: {
        CommentForm,
        CommentList,
        UiListGroupSkeleton,
    },
    props: {
        accountId: {
            type: String,
            default: null,
            required: true,
        },
        isLead: {
            type: Boolean,
            required: false,
            default: false,
        },
        scope: {
            type: String,
            required: true,
            default: null,
        }
    },
    data() {
        return {
            comment: null,
            loading: false,
            notes: [],
        };
    },
    mounted() {
        this.getNotes();
    },
    methods: {
        commentCreated(comment) {
            this.notes.unshift(comment);

            this.$emit('updateStage', {
                id: this.accountId,
                stage: comment.stage,
            });
        },
        commentUpdated(comment) {
            const searchIndex = this.notes.findIndex(item => item.id === comment.id);
            this.notes.splice(searchIndex, 1, comment);
            this.comment = null;

            if (searchIndex === 0) {
                this.$emit('updateStage', {
                    id: this.accountId,
                    stage: comment.stage,
                });
            }
        },
        editComment(comment) {
            this.comment = comment;
        },
        async getNotes() {
            try {
                this.loadingNotes = true;
                this.loading = true;

                const resource = this.isLead ? 'leads' : 'accounts';

                const { data } = await this.$axios.get(`/_/${resource}/${this.accountId}/notes`);
                
                this.notes = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    }
};
</script>

