<template>
    <div class="comment-list">
        <div v-for="comment in comments" :key="comment.id" class="comment-list__item">
            <div class="comment-list__item-header">
                <div class="comment-list__item-header-content">
                    <div class="comment-list__stage-status-container">
                        <ui-stage-status :stage="comment.stage" />
                    </div>
                    <h6 class="comment-list__item-name">{{ comment.admin.first_name }} {{ comment.admin.last_name }}</h6>
                    <small class="comment-list__item-date">{{ $dayjs(comment.created_at).format('MM/DD/YYYY, LT') }}<!-- 06/03/2023 10:00am --></small>
                </div>
                <div class="comment-list__item-header-actions" v-if="CAN([scope])">
                    <b-dropdown v-if="comment.stage !== 'IS'" variant="none" no-caret toggle-class="text-muted">
                        <template #button-content>
                            <span class="fas fa-ellipsis-v"></span>
                        </template>
                        <b-dropdown-item @click="$emit('editComment', comment)">
                            Edit comment
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <p class="comment-list__item-text">
                {{ comment.comment }}
            </p>
        </div>
        <empty-state v-if="!comments.length" img="searching-data.svg" text="There are no comments for this user yet..." />
    </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState';
import UiStageStatus from '@/components/ui/StageStatus';

export default {
    name: 'CommentList',
    components: { EmptyState, UiStageStatus },
    props: {
        comments: {
            type: Array,
            required: true,
            default: () => [],
        },
        scope: {
            type: String,
            required: true,
            default: null,
        }
    },
};
</script>

<style lang="scss">
.comment-list {
    &__item {
        &:not(:last-child) {
            padding-bottom: 15px;
            margin-bottom: 20px;
            border-bottom: 1px solid $ecart-secondary-100;
        }
    }

    &__item-header {
        display: flex;
    }

    &__item-header-content {
        align-items: center;
        display: flex;
        flex: 1;
    }

    &__stage-status-container {
        margin-right: 10px;
    }

    &__item-name {
        color: $general-black;
        font-size: 1.03rem;
        margin-bottom: 0;
        margin-right: 10px;
    }
    
    &__item-date {
        color: $ecart-secondary-400;
    }

    &__item-text {
        color: $ecart-secondary-500;
        margin-bottom: 0;
        padding-left: 45px;
    }
}
</style>