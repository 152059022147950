<template>
    <div class="stage-status" :class="`stage-status--${stage}`" v-b-tooltip="stages[stage]">
        {{ stage }}
    </div>
</template>

<script>
export default {
    name: 'StageStatus',
    props: {
        stage: {
            type: String,
            default: null,
            required: true,
        },
    },
    data() {
        return {
            stages: {
                NA: 'Not available',
                CO: 'Contact / With tour',
                CN: 'Contact / No tour',
                PR: 'Proposal',
                NR: 'Negotiation / Review',
                CW: 'Closed / Won',
                CL: 'Closed / Lost',
            },
        };
    },
};
</script>

<style lang="scss">
.stage-status {
    align-items: center;
    background-color: $ecart-secondary-200;
    border-radius: 100%;
    color: $ecart-secondary-500;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 35px;
    justify-content: center;
    width: 35px;
    line-height: 1;

    &--NA {
        background-color: rgba($ecart-secondary-200, 0.2);
        color: $ecart-secondary-500;
    }

    &--CO {
        background-color: rgba($general-info, 0.2);
        color: $general-info;
    }

    &--CN {
        background-color: $ecart-secondary-200;
        color: $ecart-secondary-500; 
    }

    &--PR {
        background-color: rgba($general-warning, 0.2);
        color: $general-warning;
    }

    &--NR {
        background-color: rgba($general-pending, 0.3);
        color: #dda720;
    }

    &--CW {
        background-color: rgba($general-success, 0.2);
        color: $general-success;
    }

    &--CL {
        background-color: rgba($general-error, 0.2);
        color: $general-error;
    }

    &--IS {
        background-color: $ecart-accent-400;
        color: $general-white;
    }
}
</style>
