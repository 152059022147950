<template>
    <form class="comment-form" @submit.prevent="handleSubmit">
        <small v-if="comment" class="d-block mb-2">
            Editing comment #{{ comment.id }}
        </small>
        <ui-select
            id="type"
            class="w-100 mb-3"
            name="type"
            label="Stage"
            option-label="title"
            option-value="value"
            v-model:value="formData.stage"
            :show-choose-option-text="false"
            :options="stages"
            :field="v$.formData.stage"
        />
        <div
            class="comment-form__text-area-container"
            :class="{
                'comment-form__text-area-container--valid': v$.formData.comment.$dirty && !v$.formData.comment.$error,
                'comment-form__text-area-container--invalid': v$.formData.comment.$dirty && v$.formData.comment.$error,
            }"
        >
            <textarea id="comment" v-model="formData.comment" placeholder=" " class="comment-form__text-area-input"></textarea>
            <label for="comment" class="comment-form__text-area-label">
                Enter your comments
            </label>
            <div class="comment-form__text-area-actions">
                <ui-button v-if="comment" class="me-2" variant="secondary" :disabled="loading" @click="clearForm">
                    Cancel
                </ui-button>
                <ui-button type="submit" variant="primary" :loading="loading">
                    {{ !comment ? 'Add comment' : 'Update comment'}}
                </ui-button>
            </div>
        </div>
        <div v-if="v$.formData.comment && v$.formData.comment.$error" class="text-danger mt-1">
            {{ v$.formData.comment.$errors[0].$message }}
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import UiButton from '@/components/ui/buttons/Button';
import UiSelect from '@/components/ui/Select';

export default {
    name: 'CommentForm',
    props: {
        comment: {
            type: Object,
            default: null,
            required: false,
        },
        accountId: {
            type: String,
            default: null,
            required: true,
        },
        isLead: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        UiButton,
        UiSelect,
    },
    setup: () => ({ v$: useVuelidate() }),
    data(){
        return {
            formData: {
                stage: '',
                comment: '',
            },
            stages: [
                {
                    title: 'Contact / With tour',
                    value: 'CO',
                },
                {
                    title: 'Contact / No tour',
                    value: 'CN',
                },
                {
                    title: 'Proposal',
                    value: 'PR',
                },
                {
                    title: 'Negotiation / Review',
                    value: 'NR',
                },
                {
                    title: 'Closed / Won',
                    value: 'CW',
                },
                {
                    title: 'Closed / Lost',
                    value: 'CL',
                },
            ],
            loading: false,
        };
    },
    watch: {
        comment(value) {
            if (value) {
                this.formData.stage = value.stage;
                this.formData.comment = value.comment;
            }
        },
    },
    validations() {
        return {
            formData: {
                stage: {
                    required: helpers.withMessage('The field stage is required.', required),
                    $autoDirty: true,
                },
                comment: {
                    required: helpers.withMessage('The field comment is required.', required),
                    $autoDirty: true,
                },
            },
        };
    },
    methods: {
        clearForm() {
            this.formData = { stage: '', comment: '' };
            this.v$.$reset();
            this.$emit('clearForm');
        },
        handleSubmit() {
            if (this.comment) {
                this.updateComment();
                return;
            }

            this.addComment();
        },
        async addComment() {
            try {
                if (!await this.v$.$validate()) {
                    return;
                }

                this.loading = true;

                const payload = { ...this.formData };

                if (this.isLead) {
                    payload.lead_id = this.accountId;
                } else {
                    payload.account_id = this.accountId;
                }

                const { data } = await this.$axios.post('/_/notes', payload);

                this.$emit('commentCreated', data);
                this.clearForm();
                this.$toast.success('Comment created successfully');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        async updateComment() {
            try {
                if (!await this.v$.$validate()) {
                    return;
                }

                this.loading = true;

                const { data } = await this.$axios.put(`/_/notes/${this.comment.id}`, this.formData);

                this.$emit('commentUpdated', data);
                this.clearForm();
                this.$toast.success('Comment updated successfully');

            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
};

</script>
<style lang="scss">
.comment-form {
    &__text-area-container {
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 4.5px 8px 16px 8px;
        width: 100%;

        &:focus-within {
            border-color: $ecart-secondary-200;
            box-shadow: none;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }
    }

    &__text-area-label {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        color: $ecart-secondary-400;
        line-height: 1;
        position: absolute;
        left: 8px;
        top: calc(20px - 7px);
        transition: 200ms all;
    }

    &__text-area-input {
        background-color: transparent;
        background-image: none;
        border: none;
        border-radius: 0;
        color: $general-black;
        font-size: 14px;
        font-weight: 500;
        height: auto;
        line-height: 1;
        outline: 0;
        padding: 0;
        padding-top: 15px;
        resize: none;
        width: 100%;

        &::placeholder {
            opacity: 0;
            color: $ecart-secondary-400;
            font-weight: 400;
        }

        &:focus {
            border: none;
            background-color: transparent;

            &::placeholder {
                transition: opacity 200ms ease-in;
                opacity: 1;
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            -webkit-background-clip: text;
        }
    }

    &__text-area-actions {
        border-top: 1px solid $ecart-secondary-200;
        display: flex;
        justify-content: flex-end;
        padding-top: 16px;
        width: 100%;
    }
}

.comment-form__text-area-input:focus ~ .comment-form__text-area-label,
.comment-form__text-area-input:not(:placeholder-shown) ~ .comment-form__text-area-label {
    color: $general-black;
    font-size: 12px;
    top: 4px;
}

.comment-form__text-area-input:focus,
.comment-form__text-area-input:not(:placeholder-shown) {
    bottom: 4px;
    top: auto;
}
</style>